import { createRouter, createWebHashHistory } from 'vue-router';

const routes = [
    {
        path: '/',
        name: 'app',
        component: () => import('../App.vue'),
        children: [
            {
                path: '',
                name: 'Landing',
                component: () => import('../pages/Landing.vue')
            },
            {
                path: '/MisCitas',
                name: 'Mis Citas',
                meta: {authRequired: true},
                component: () => import('../pages/MisCitas.vue')
            },
            {
                path: '/Usuarios',
                name: 'Usuarios',
                meta: {authRequired: true},
                component: () => import('../pages/Usuarios.vue')
            },
            {
                path: '/Roles',
                name: 'Roles',
                meta: {authRequired: true},
                component: () => import('../pages/Roles.vue')
            },
            {
                path: '/Especialidades',
                name: 'Especialidades',
                meta: {authRequired: true},
                component: () => import('../pages/Especialidades.vue')
            },
            {
                path: '/Horas',
                name: 'Bloque de Horas',
                meta: {authRequired: true},
                component: () => import('../pages/Horas.vue')
            },
            {
                path: '/Espacios',
                name: 'Espacios Físicos',
                meta: {authRequired: true},
                component: () => import('../pages/Espacios.vue')
            },
            {
                path: '/Especialistas',
                name: 'Especialistas',
                meta: {authRequired: true},
                component: () => import('../pages/Especialistas.vue')
            },
            {
                path: '/Pacientes',
                name: 'Pacientes',
                meta: {authRequired: true},
                component: () => import('../pages/Pacientes.vue')
            },
            {
                path: '/Proveedores',
                name: 'Proveedores',
                meta: {authRequired: true},
                component: () => import('../pages/Proveedores.vue')
            },
            {
                path: '/Auditoria',
                name: 'Auditoría',
                meta: {authRequired: true},
                component: () => import('../pages/Auditoria.vue')
            },
            {
                path: '/Aranceles',
                name: 'Aranceles',
                meta: {authRequired: true},
                component: () => import('../pages/Aranceles.vue')
            },
            {
                path: '/Horarios',
                name: 'Horarios',
                meta: {authRequired: true},
                component: () => import('../pages/Horarios.vue')
            },
            {
                path: '/Dashboard',
                name: 'Dashboard',
                meta: {authRequired: true},
                component: () => import('../pages/Dashboard.vue')
            },
            {
                path: '/Notificacion',
                name: 'Notificaciones',
                meta: {authRequired: true},
                component: () => import('../pages/Notificacion.vue')
            },
            {
                path: '/Seguimiento',
                name: 'Seguimiento',
                meta: {authRequired: true},
                component: () => import('../pages/Seguimiento.vue')
            },
            {
                path: '/Procesar',
                name: 'Procesar',
                meta: {authRequired: true},
                component: () => import('../pages/Procesar.vue')
            },
            {
                path: '/Facturacion',
                name: 'Facturacion',
                meta: {authRequired: true},
                component: () => import('../pages/Facturacion.vue')
            },
            {
                path: '/Recibo',
                name: 'Recibo',
                meta: {authRequired: true},
                component: () => import('../pages/Recibo.vue')
            },
            {
                path: '/CuentaPagar',
                name: 'Cuenta x Pagar',
                meta: {authRequired: true},
                component: () => import('../pages/CuentaPagar.vue')
            },
            {
                path: '/CuentaCobrar',
                name: 'Cuenta x Cobrar',
                meta: {authRequired: true},
                component: () => import('../pages/CuentaCobrar.vue')
            },
            {
                path: '/CuentaImpuesto',
                name: 'SENIAT',
                meta: {authRequired: true},
                component: () => import('../pages/CuentaImpuesto.vue')
            },
            {
                path: '/Honorarios',
                name: 'Honorarios Profesionales',
                meta: {authRequired: true},
                component: () => import('../pages/Honorarios.vue')
            },
            {
                path: '/Balance',
                name: 'Balance',
                meta: {authRequired: true},
                component: () => import('../pages/Balance.vue')
            },
            {
                path: '/Pagos',
                name: 'Consulta de Pagos',
                meta: {authRequired: true},
                component: () => import('../pages/Pagos.vue')
            },
            {
                path: '/MisPacientes',
                name: 'Mis Pacientes',
                meta: {authRequired: true},
                component: () => import('../pages/MisPacientes.vue')
            },
            {
                path: '/Indicaciones',
                name: 'Indicaciones',
                meta: {authRequired: true},
                component: () => import('../pages/Indicaciones.vue')
            },
            {
                path: '/Historial',
                name: 'Historial',
                meta: {authRequired: true},
                component: () => import('../pages/Historial.vue')
            },
            {
                path: '/Personal',
                name: 'Personal',
                meta: {authRequired: true},
                component: () => import('../pages/Personal.vue')
            },
            {
                path: '/Nomina',
                name: 'Nomina',
                meta: {authRequired: true},
                component: () => import('../pages/Nomina.vue')
            },
            {
                path: '/LandingPage',
                name: 'LandingPage',
                meta: {authRequired: true},
                component: () => import('../pages/LandingPage.vue')
            },



            {
                path: '/Video',
                name: 'Video',
                component: () => import('../components/Video.vue')
            },


        
        ]
    },
    {
        path: '/QR',
        name: 'QR',
        component: () => import('../pages/QR.vue')
    },


];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

export default router;
