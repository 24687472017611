import { createStore } from 'vuex'
import API from "../service/API";
import router from '../routes';

export default createStore({
  state: {
    mobile: false,
    loading: false,
    estatus: null,
    clave: null,
    usuario: 0,
    nombre: null,
    insert: false,
    edit: false,
    delete: false,
    to: null,

    foto: null,
    authenticated: false,
    MenuActive: true,
    MenuActiveMobile: false,
    error: null,
    fecha: null,
    id: 0,

    admin: false,
    menu: [],
    menuadmin: [],
    menuexterno: [],
    paginas: [],
    
    view: {
      login: false,
      register: false,
      landing: null,
      cita: false,
      conexion: false,
    },
    
    sexos: [],
    especialidades: [],
    estados: [],
    ciudades: [],
    monedas: [],
    dias: [],
    horas: [],

    tasa: 0,
    pago: {
      display: false,
      deuda: 0,
      deudabs: 0,
      datos: [],
      monto: 0,
      montobs: 0,
      tasa: 0,
      
    },
    meses: [
      { id: 0,  nombre: 'Todos' },
      { id: 1,  nombre: 'Enero' },
      { id: 2,  nombre: 'Febrero' },
      { id: 3,  nombre: 'Marzo' },
      { id: 4,  nombre: 'Abril' },
      { id: 5,  nombre: 'Mayo' },
      { id: 6,  nombre: 'Junio' },
      { id: 7,  nombre: 'Julio' },
      { id: 8,  nombre: 'Agosto' },
      { id: 9,  nombre: 'Septiembre' },
      { id: 10, nombre: 'Octubre' },
      { id: 11, nombre: 'Noviembre' },
      { id: 12, nombre: 'Diciembre' },
    ],
    year: null,
    years: [],
    opc: true,
    ini: null,
    fin: null,
    carrito: [],
    productos: [
      {   
        id: 1,
        code: "f230fh0g3",
        name: "Bamboo Watch",
        description: "Product Description",
        image: "prod1.jpg",
        price: 65,
        category: "Accessories",
        quantity: 24,
        inventoryStatus: "INSTOCK",
        rating: 5
      },
      {   
        id: 2,
        code: "f230fh0g3",
        name: "Bamboo Watch",
        description: "Product Description",
        image: "prod1.jpg",
        price: 65,
        category: "Accessories",
        quantity: 24,
        inventoryStatus: "INSTOCK",
        rating: 5
    },
    {   
      id: 3,
      code: "f230fh0g3",
      name: "Bamboo Watch",
      description: "Product Description",
      image: "prod1.jpg",
      price: 65,
      category: "Accessories",
      quantity: 24,
      inventoryStatus: "INSTOCK",
      rating: 5
    },
    {   
      id: 4,
      code: "f230fh0g3",
      name: "Bamboo Watch",
      description: "Product Description",
      image: "prod1.jpg",
      price: 65,
      category: "Accessories",
      quantity: 24,
      inventoryStatus: "INSTOCK",
      rating: 5
    },
    {   
    id: 5,
    code: "f230fh0g3",
    name: "Bamboo Watch",
    description: "Product Description",
    image: "prod1.jpg",
    price: 65,
    category: "Accessories",
    quantity: 24,
    inventoryStatus: "INSTOCK",
    rating: 5
    },
    {   
    id: 1,
    code: "f230fh0g3",
    name: "Bamboo Watch",
    description: "Product Description",
    image: "prod1.jpg",
    price: 65,
    category: "Accessories",
    quantity: 24,
    inventoryStatus: "INSTOCK",
    rating: 5
    },

    ],
    slider: [],
    servicios: [],
    videos: []
  },

  mutations: {
    Loading(){
      this.state.loading = !this.state.loading;	
    },
    MenuActive(){
      if(this.state.mobile){
        this.state.MenuActiveMobile = !this.state.MenuActiveMobile;
      } else {
        this.state.MenuActive = !this.state.MenuActive;
      }	
    },
    Mobile(){
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
        || screen.width<1024) {
          this.state.mobile = true;
      } else {
        this.state.mobile = false;
      }    
    },
    Estatus(){
      const Consulta = new API();
      Consulta.Estatus().then(response => {
        //this.state.error = response;
        this.state.slider = response.basico.slider;
        this.state.servicios = response.basico.servicios;
        this.state.videos = response.basico.videos;
        this.state.estatus = response.estatus;
        this.state.fecha = response.fecha;
        this.state.ini = response.fecha;
        this.state.fin = response.fecha;
        for(var i = response.year; i >= 2022; --i) { 
          this.state.years.push({
            id: i,
            nombre: i
          });
        }
        this.state.mes = this.state.meses.filter( val => val.id == response.mes)[0]; 
        this.state.year = this.state.years.filter( val => val.id == response.year)[0]; 
        this.state.loading = false;	
      });   
    },
    Login(state,valores){
      //this.state.error = valores.precarga;
      this.state.authenticated = valores.authenticated;
      localStorage.setItem('Token', valores.token);
      this.state.id = valores.id;
      if(valores.foto){this.state.foto = valores.foto;}
      this.state.usuario = valores.id;
      this.state.nombre = valores.nombre;
      this.state.correo = valores.correo;
      this.state.admin = valores.admin;
      
      this.state.menuexterno = valores.menu.filter(val => val.modulo == 1);
      this.state.menu = valores.menu.filter(val => val.modulo > 1);
      for(var i = 0; i < valores.menu.length; ++i) { 
        valores.menu[i].items.forEach(element => {
          this.state.paginas.push(element.to);
        });  
      }
      this.state.MenuActive = false;

      this.state.sexos = valores.precarga.sexos;
      this.state.especialidades = valores.precarga.especialidades;
      this.state.estados = valores.precarga.estados;
      this.state.ciudades = valores.precarga.ciudades;
      this.state.monedas = valores.precarga.monedas;
      this.state.dias = valores.precarga.dias;
      this.state.horas = valores.precarga.horas;

      if(this.state.mobile){
        this.state.MenuActiveMobile = true;
      }

      if(this.state.paginas.includes('/Dashboard')){
        router.push({ path: '/Dashboard' });
      } else {
        if(this.state.paginas.includes('/Seguimiento')){
          router.push({ path: '/Seguimiento' });
        }        
      }



    },
    Logout(){
      const Consulta = new API('Seguridad','Exit');
      Consulta.Ini();
      localStorage.removeItem('Token');
      this.state.MenuActive = true;
      this.state.authenticated = false;
      this.state.usuario = null;   
      this.state.correo = null;
      this.state.valido = false;
      this.state.admin = false;
      this.state.paginas = [];
      router.push({ path: '/' });
      this.state.loading = false;	
    },
    Validar(state,pagina){
      this.state.insert = false;
      this.state.edit = false;
      this.state.delete = false;
      if(!this.state.paginas.includes(pagina.path)){
        router.push({ path: '/' });
      } 
    },
    AgreCarrito(state,producto){
      if(!this.state.carrito.includes(producto)){
        this.state.carrito.push(producto);
      }    
      if (this.state.authenticated) {
        localStorage.setItem('Carrito',JSON.stringify(this.state.carrito));
      }
    },
    DelCarrito(state,producto){
      this.state.carrito = this.state.carrito.filter(val => val.id !== producto.id);
      if (this.state.authenticated) {
        localStorage.setItem('Carrito',JSON.stringify(this.state.carrito));
      }
    },
    ActCarrito(){
      if (this.state.authenticated) {
        localStorage.setItem('Carrito',JSON.stringify(this.state.carrito));
      }
    },
    Tasa(){    
      fetch("https://s3.amazonaws.com/dolartoday/data.json")
        .then(r => r.json())
        .then(response => {
          this.state.tasa = parseFloat(response.USD.sicad2).toFixed(2);
      });
    },
    LimpiarPagos() {
      this.state.pago = {
        display: false,
        deuda: 0,
        datos: [],
        monto: 0,
        montobs: 0,
        tasa: 0,
      };
    },

  },
  actions: {
  },
  modules: {
  }
})
