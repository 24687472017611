import axios from "axios";
import store from "../store";
import router from '../routes';

const URL = process.env.VUE_APP_API;

const API = function (parameters){
    const http = axios.post(URL, parameters, { timeout: 60000 });
    return http; 
}

class APIs {
    constructor(modulo=null,funcion=null) {
        this.modulo =   modulo;
        this.funcion =  funcion;
        this.token =    localStorage.getItem('Token');
        store.state.view.conexion = false; 
    }
    Estatus() {
        store.state.loading = true;
        return API({
            modulo:     this.modulo
        }).then(res => res.data).catch(error => { 
            this.ErrorConexion(error);
        });
    }
    Ini() {
        store.state.loading = true;
        return API({
            modulo:     this.modulo,
            funcion:    this.funcion,
            token:      this.token
        }).then(res => res.data).catch(error => { 
            this.ErrorConexion(error);
        });
	}
    Procesar(caso,array) {
        store.state.loading = true;
        return API({
            modulo:     this.modulo,
            funcion:    this.funcion,
            token:      this.token,
            caso:       caso,
            array:      array
        }).then(res => res.data).catch(error => { 
            this.ErrorConexion(error);
        });
    }
    ErrorConexion(error) {
        console.log(error);
        store.state.loading = false;
        store.state.MenuActive = true;
        store.state.view.conexion = true;
        store.state.authenticated = false;
        store.state.usuario = null;
        store.state.correo = null;
        store.state.valido = false;
        store.state.admin = false;
        store.state.paginas = [];
        store.state.menu = [];
        store.state.menuexterno = [];
        router.push({ path: '/' });  
    }
    Mens(estatus) {
        if(estatus){
            return {
                severity:'success', 
                summary: 'Éxito', 
                detail: 'Información Procesada', 
                life: 3000
            };
        } else {
            return {
                severity:'warn', 
                summary: 'Advertencia', 
                detail: 'No fue procesada la solicitud', 
                life: 3000
            };
        }
    }

   
}

export default APIs;